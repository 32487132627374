.testimonials {
    margin-left: 13.2vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;   
    height: 100%;
}

.testimonials h1 {
    margin-top: 2rem;
    font-size: 3rem;
    font-weight: 700;
    color: var(--color-bg);
    opacity: 0.5;
}

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    margin: 6rem;
}

.card {
    width: 15rem;
    height: 25rem;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px var(--color-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem;
    margin: 1.2rem;
    transition: all 1s ease;
}


.card:hover {
    transform: scale(1.1);
}

.top {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 30px;
}

.center {
    padding: 0.5rem;
    border-radius: 10px;
    font-size: 0.65rem;
}

.center:hover {
    background: transparent;
}

.bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.bottom h6 {
   font-size: smaller;
}


@media screen and (max-width: 1024px) {
    .testimonials {
        margin-left: 0;
    }

    .container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        justify-content: center;
        align-items: center;     
    }

    .card {
        width: 17rem;
        padding: 0;
    }
    

    .center {
        font-size: 0.7rem;
    }
}


@media screen and (max-width: 600px) {

    .testimonials {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}