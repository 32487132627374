.estimate {
    margin-left: 14vw; 
    margin-bottom: 100px;       
}
.estimate-h1 {
    text-align: center;
    padding: 15px;
    font-size: 4rem;
    font-weight: 600;
    color:rgb(223, 220, 220);
    text-transform: uppercase;
}
.estimate-wrap {
    margin-left: 2vw;
    border: 4px solid rgba(223, 220, 220, 0.4);
    width: 80vw;
    padding: 4rem;
    
    
}
.input-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.estimate-btn {
    margin-top: 2rem;
    background-color: rgb(255, 102, 0);
    padding: 15px;
    border: none; 
    border-radius: 5%;   
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    color: white;
    text-align: center;
    opacity: 0.9;
}
.estimate::after {
    background-color: orange;
}
.address-wrap {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}


@media screen and (max-width: 1024px) {
    .estimate {
        margin-left: 0;
        margin-top: 9rem;
        
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .estimate-wrap {
        width: 95%;
        padding: 5px;
        
    }    
}

@media screen and (max-width: 600px) {
    .estimate-wrap {
        font-size: 13px;
    }

    .estimate-btn {
        font-size: 14px;
        padding: 0.6rem;
        margin-bottom: 2rem;
    }
    
}

