.touch {  
    width: 100%;   
    height: 100px;
    border: 1px solid orange;
    border: none;
    background: var(--color-primary);
    color: white;     
    text-align: center;
    padding: 2rem;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
}