@import '../../index.css';

.about {     
    margin-left: 13.02vw;      
    display: flex;
    flex-direction: row;
}
.left-about {
    flex: 0.4;
    margin: 2rem;
}

.title-about {
    padding: 1rem;
    font-size: 4rem;
    font-weight: 600;
    color: rgb(223, 220, 220);
}

.about-torres {
    margin-bottom: 2rem;
    line-height: 2;
    font-style: italic;
    font-size: 1rem;
    letter-spacing: 1.3px;
    color: gray;  
    text-align: justify; 
}

.about-torres2 {
    line-height: 2;
    font-size: 15px;
    letter-spacing: 1.2px;
    color: gray;
    text-align: justify;
    margin-bottom: 4rem;
}

.btn-about {
    width: fit-content;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    cursor: pointer;
    text-align: center; 
    margin: 2rem;   
}

.btn-about:hover { 
    text-decoration: none;
    color: var(--color-white);
    padding: 0.9rem;
}

.right-about {
    flex:0.6;   
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;  
    
}

.about-img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .about {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about-img {
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 600px){
    
    .left-about {
        margin-top: 0;
    }
    .title-about {
        font-size: 1.7rem;
        margin: 0;
        letter-spacing: 0;
    }
    .about-torres, .about-torres2 {
        letter-spacing: 0;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
    }
    .btn-about {
       font-size: 13px;
       padding: 0.6rem;
       margin: 0;
       color: white;
    }
}