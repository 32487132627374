.menu {
    width: 13.02vw;
    height: 100%;
    background-color: var(--color-bg-menu);
    position: fixed;    
    left: 0;   
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    z-index: 2;
    transition: all 1s ease;    
}

.logo {   
    margin: 2rem 0 5rem 0;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul {
    margin: 0;
    padding: 1.5rem;
    list-style: none;
    font-size: 0.9rem;
    color: rgb(229, 221, 221);
    
}

.navbar-li {
    border-bottom: 1px solid rgba(248, 247, 246, 0.2);
    width: 10vw;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.nav-last {
    border: none;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;   
}

a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.navbar-li a:hover {
    font-size: 1rem;
    /* color: rgb(255, 102, 0); */
    color: var(--color-primary);
}

a.active {
    color: var(--color-primary); 
    
}

.icon {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}

.hamburger {
    display: flex;
}
.burger-nav {
    display: none;
}

@media screen and (max-width: 1024px) {
    .menu {             
        height:150px; 
        width: 100%;       
        display: grid;
        grid-template-columns: 1fr 2fr;
        justify-content: space-between;
        gap: 6rem;       
    }   
    
    .logo {
        margin: 0;
    }
    
    ul {
        display: none;
    }
    

    .wrap-hamburger {               
        position: fixed;
        display: flex;
        flex-direction: row; 
        gap: 32rem;
        margin: 1.5rem;
        justify-content: space-between;             
        color: white;
        right: 0;  
        top: 0; 
    }  
    
}

@media screen and (max-width: 600px) {

    .logo {
        margin: 0;
    }
    .wrap-hamburger {
        background: transparent;
        font-size: 0.5rem;
    }

    .wrap-hamburger {
        gap: 8rem;
        margin: 1.2rem; 
    }
}


